import { } from "../../node_modules/jquery/dist/jquery.min.js";

let $toggle = $(".topnav__toggle");

export function topnav() {
	$toggle.on("click", function (e) {
		e.preventDefault();
		$(this).parent().toggleClass("is_visible");
	})
}
